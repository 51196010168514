import './bootstrap';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

$("#startDate").val(new Date().toJSON().slice(0,19));


$("td").click(function () {
    $(this).find('input:radio').attr('checked', true);
 });